<template>
  <div id="Story" class="buybox"> 
    <div class="buybox_right">
      <h2 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">Story</h2>
      <!-- <h2><img src="../../assets/image/meh_title.png" alt=""></h2> -->
      <!-- <p style="">The first NFT <span class="fontFamilyNone">-</span> 
        fi social protocol on Arbitrum.
      </p> -->
      <div class="banner_subimg wow fadeInDown" data-wow-duration="1s" data-wow-delay=".2s">
        <!-- <img src="@/assets/image/bscm/subTitle.png" v-if="!$utils.isMobile()">
        <img src="@/assets/image/bscm/h5_subTitle.png" v-else> -->
      </div>
      
      <div class="banner wow fadeInDown" data-wow-duration="1s" data-wow-delay=".25s">
        
        <div class="swiper-container mySwiper02">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/image/bscm/banner01.webp" v-if="!$utils.isMobile()">
              <img src="@/assets/image/bscm/banner01_m.webp" v-else>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/image/bscm/banner02.webp" v-if="!$utils.isMobile()">
              <img src="@/assets/image/bscm/banner02_m.webp" v-else>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      

    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay,PaginationClickable } from "swiper"
import 'swiper/swiper-bundle.css'
Swiper.use([Navigation, Pagination, Autoplay])
export default {
  created(){
    console.log(this.$utils.isMobile())
    
  },
    mounted(){
      var options={
          //默认为true
          live:false
      }
      new this.$WOW.WOW(options).init();
      this.init()
    },
    data() {
      return {

      }
    },
    methods:{
      init(){
        this.$nextTick(() => {
          var swiper = new Swiper(".mySwiper02", {
            loop: true,
            pagination: {
              clickable :true,
              el: '.swiper-pagination',
              type: 'bullets',
            },
          });
        })
      }
    }
  };
</script>

<style>
.fontFamilyNone{
  font-family: 'Amatic SC Bold';
}
.swiper-pagination-bullet{
  background: #474039 !important;
  margin:0px 3px;
  width: 18px;
  height: 2px;
  opacity: 1 !important;
  border-radius: 0px ;
}
.swiper-pagination-bullet-active{
  width: 68px;
  height: 2px;
  background: #847058 !important;
}
</style>

<style lang="scss" scoped>
.banner_subimg{
  max-width: 1044px;
  margin: 43px auto 0;
  img{
    width:100%
  }
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 0px;
}

.swiper {
  width: 100%;
  // height: 536px;
}
.swiper-pagination{
  width: 100%;
  height: 20px;
}
.swiper-slide {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  // display: -ms-flexbox;
  // display: -webkit-flex;
  // display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner{
  width: 100%;
  max-width: 1100px;
  margin-top: 0px;
  position: relative;
  height: 683px;
  z-index: 0;
  .banner_img{
    width: 100%;
    
  }
}
.buybox{
  display: flex;
  justify-content: center;
  margin-top: 90px;
  position: relative;
  padding-bottom: 80px;
  width: 100%;
}
.buybox::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  background: url('../../assets/image/line.png') no-repeat center center/100%;
}
.buybox_right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h2{
    color: #F8C10A;
    font-size: 36px;
    margin: 14px 0px 7px;
    text-transform: uppercase;
    img{
      width: 180px;
    }
  }
  p{
    text-align: center;
    color: #BFBFBF;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
  ul{
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
      width: 188px;
      margin-bottom: 60px;
        margin-right: 74px;
      &:not(:nth-child(4n)){
      }
    }
  }

}
.backF8BA00{
  background: #F8BA00 !important;
  padding: 0px 40px !important;
}
@media screen and (max-width:1200px) {
  
  .banner{
    max-width: 900px;
    margin-top: 20px;
    position: relative;
    height: 366px;
    .banner_img{
      width: 100%;
      
    }
  }
}
@media screen and (max-width:768px) {
  .banner_subimg{
    max-width: 322px;
    margin: 14px auto 0;
  }
  .banner{
    max-width: 355px;
    margin-top: 0px;
    position: relative;
    height: 1032px;
    .banner_img{
      width: 100%;
    }
  }

  .pc_showP{
    display: none;
  }
  .h5_show{
    display: block;
  }
  .buybox{
    width: 100%;
    margin-top: 50px;
    padding-bottom: 0;
    flex-wrap: wrap;
    &::before{
      display: none;
    }
    h2{
      font-size: 30px;
      margin: 10px 0px 10px;
    }

  }
  .buybox_right{
    width: 100%;
    p{
      line-height: 1.6;
      text-align: center;
    }
    ul{
      width: 100%;
      li{
        width: 164px;
        margin-right: 15px;
        margin-bottom: 40px;
      }
    }
    h2{
      img{
        width: 113px;
      }
    }
  }

  .banner{
    margin-top: 6px;
  }

  .backF8BA00{
    padding: 0px 12px !important;
  }
}
@media screen and (max-width:480px) {
  .buybox_right{
    p{
      font-size: 13px;
    }
    ul{
      li{
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
}
</style>